/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// exports.onClientEntry = () => {
//   if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.getRegistrations('/').then(registrations => {
//       registrations.map(reg => reg.unregister())
//     })
//   }
// }

export const onClientEntry = async () => {
   if (typeof IntersectionObserver === `undefined`) {
      await import(`intersection-observer`)
   }
}
